@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  @apply h-full p-0 m-0;
}

#root {
  @apply h-full;
}