@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Ephesis&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&family=Great+Vibes&display=swap');

body {
  @apply bg-white;
}

.bg-custom {
  @apply border-0 border-s-4 border-e-4 border-white bg-pink;
}

.bg-custom-2 {
  @apply border-0 border-s-8 border-e-8 bg-pink border-pink;
}


.animation-waves {
  animation: waves-float infinite 6s ease-in-out;
}

.animation-clouds {
  animation: Cloud-float infinite 8s ease-in-out;
}

.animation-clouds-2 {
  animation: Cloud-float-2 infinite 11s ease-in-out;
}

@keyframes waves-float {
  0% {
    transform: translateX(-50%);
  }
  50% {
    transform: translateX(-53%);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes Cloud-float {
  0% {
    scale: 97%;
  }
  50% {
    scale: 100%;
  }
  100% {
    scale: 97%;
  }
}

@keyframes Cloud-float-2 {
  0% {
    scale: 100%;
  }
  50% {
    scale: 95%;
  }
  100% {
    scale: 100%;
  }
}

.title {
  font-family: "Great Vibes", cursive;
  @apply text-black
}


.invite {
  font-family: "Baskervville", serif;
}

/*axadsadasd*/


@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
